
  import { defineComponent } from 'vue';
  import { useQuery } from '@vue/apollo-composable';

  import gql from 'graphql-tag';

  export default defineComponent({
    setup() {
      const { result } = useQuery(gql`
        query {
          collections {
            id
            title
            objects {
              id
            }
          }
        }
      `);

      return {
        result,
      };
    },
  });
